@import "~@angular/material/theming";
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,700;0,900;1,300;1,400;1,700;1,900&family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@include mat-core();
$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink, 300, 100, 900);
$app-warn: mat-palette($mat-red);
$success: rgb(48, 143, 48);
$theme: mat-light-theme($app-primary, $app-accent, $app-warn);
$custom-typography: mat-typography-config(
  $font-family: "Merriweather",
  $headline: mat-typography-level(26px, 35px, 400),
  $title: mat-typography-level(36px, 12px, bold),
  $subheading-2: mat-typography-level(22px, 24px, 500),
  $subheading-1: mat-typography-level(16px, 24px, 500),
  $body-2: mat-typography-level(24px, 24px, 500),
  $body-1: mat-typography-level(18px, 24px, 500),
);
@include mat-core($custom-typography);
@include angular-material-theme($theme);
* {
  padding: 0;
  margin: 0;
}
.cdk-overlay-container {
  z-index: 10000 !important;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  cursor: pointer;
}
ins {
  iframe {
    width: 100% !important;
  }
}
ins {
  a {
    img {
      width: 100% !important;
    }
  }
}
ins {
  img {
    width: 100% !important;
  }
}
.mat-dialog-container {
  // background-color: #333 !important;
  z-index: 99999;
  padding: 35px 0px 0px 0px !important;
  overflow: hidden !important;
  position: relative !important;
}
.cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 80%) !important;
}
blockquote {
  min-width: 275px !important;
}
iframe {
  min-width: 275px !important;
}
